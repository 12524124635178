
/* ==========================================================================
   Author's custom styles
   ========================================================================== */

/*==================================================
 * 共通
 *================================================*/
/*共通部分*/

body{
    font-family: $fontJp;
}
main{
    position: relative;
    top: 120px;
    padding-bottom: 60px;
    @include sp{
        top: 65px;   
    }
    @include tab{
        top: 80px;   
    }
}

//hoverアクション
@mixin hoverBd{
    position: relative;
    &:after{
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 4px;
        background: $subColorLight;
        transform: scale(0, 1);
        transform-origin: left top;
        transition: transform .3s;    
        a:hover &{
            transform: scale(1, 1);
        }
    }
}

//fontawesome
@mixin fontawesome{
    font-family: "Font Awesome 5 Pro";
}




.telA{
    i{
        margin-right: 0.15em;
    }
    &.white{
        &:hover{
            color: #fff;
        }
    }
}

.textButton{
    position: relative;
    display: inline-block;
    color: $mainColor;
    font-weight: bold;
    transition: 0.5s;
    span{
        line-height: 1;
        padding-right: 2.5em;
    }
    a:hover &{
        color: $subColor;
        &:before{
            transform: scale(0,1);
            transform-origin: right top;
            transition: transform .6s cubic-bezier(.37,.16,.12,1),-webkit-transform .6s cubic-bezier(.37,.16,.12,1);
        }
        &:after{
            transform: scale(1,1);
            transform-origin: left top;
            transition: transform .6s cubic-bezier(.37,.16,.12,1),-webkit-transform .6s cubic-bezier(.37,.16,.12,1);
        }
    }
    &:before,&:after{
        position: absolute;
        content: '';
        display: block;
        width: 100%;
        height: 0.05em;
        bottom: 0;
        left: 0;
        transition: transform .3s cubic-bezier(.37,.16,.12,1),-webkit-transform .3s cubic-bezier(.37,.16,.12,1);
    }
    /*before デフォルト*/
    &:before{
        z-index: 0;
        background-color: $mainColor;
        transform: scale(1,1);
        transform-origin: left top;
    }
    a:hover::before{
        
    }
    /*after hover時*/
    &:after{
        z-index: 1;
        background-color: $subColor;
        transform: scale(0,1);
        transform-origin: right top;
    }
    &.block{
        display: block;
        &:before,&:after{
            height: 0.17em;
            bottom: -0.3em;
        }

    }
}

/*ローディングスピナー*/
/* ローディング画面 */
#loading {
    width: 100vw;
    height: 100vh;
    transition: all 1s;
    background-color: $mainColor;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
}


.spinner {
    width: 50px;
    height: 50px;
    margin: auto;
    background-color: #fff;
    border-radius: 100%;
    animation: sk-scaleout 1.0s infinite ease-in-out;
}
/* ローディングアニメーション */
@keyframes sk-scaleout {
    0% {
        transform: scale(0);
    } 100% {
        transform: scale(1.0);
        opacity: 0;
    }
}
.loaded {
    opacity: 0;
    visibility: hidden;
}

.vertical{
    writing-mode:vertical-rl;
}
.verticalReverse{
    writing-mode:vertical-lr;
}

.verticalPc{
    @include sp{
        writing-mode: horizontal-tb;
    }
}
.titleWrap{
    @include pc{
        border-bottom: 1px solid #9f9f9f;
        margin-bottom: 2.5em;        
    }
}
.titleBox{
    margin-bottom: 2.5em;
    &:after{
        width: 100%;
        position: absolute;
        left: 0;
        background: #9f9f9f;
        content: '';
        display: block;
        height: 1px;
    }
    &.sub{
        &:after{
            display: none;
        }
    }
    @include pc{
        &.bdLess{
            margin-bottom: 0;
            &:after{
                display: none;
            }
        }
    }
}
.titleEn{
    position: relative;
    &:before{
        content: '';
        display: block;
        width: 1.5em;
        height: 4px;
        background: #000;
        bottom: 0;
        position: absolute;
    }
    .sub &{
        padding-bottom: 0.2em;
        &:before{
            width: 2em;
            height: 3px;            
        }
    }
    @include sp{
        &.fsM{
            font-size: 28px;
            line-height: 2.1;
        }        
    }

}

//hoverアクション
@mixin hoverBdBottom{
    content: '';
    display: block;
    width: 2em;
    height: 2px;
    background: transparent;
    position: absolute;
    bottom: -0.5em;
    margin: auto;
    text-align: center;
    transition: 0.3s;
    left: 0;
    right: 0;
    a:hover &{
        background: #000;        
    }
}

/*==================================================
 * ヘッダー
 *================================================*/

header{
    margin-top: 20px;
    position: absolute;
    width: 100%;
    z-index: 100;
    @include sp{
        margin-top: 0;   
    }
}

.headerLogo{
    width: 20%;
    margin-left: 5%;
    margin-right: 5%;
    .logo{
        width: 140px;
    }
    @include sp{
        width: 18%;
        margin-left: 2%;
        margin-top: 2%;
        position: fixed;
    }
    @include tab{
        width: 10%;   
        margin-left: 2%;
        margin-right: 2%;        
    }
}
.headerMenu{
    width: 40%;
    margin-top: 2.5%;
    @include tab{
        margin-top: 0.5%;   
    }
}
.headerMenuUl{
    li{
        margin: auto 5%;
        position: relative;
        &:before{
            @include hoverBdBottom;
        }
        &:hover{
            &:before{
                background: #000;
            }
        }

    }
    @include tab{
        li{
            margin: auto 3%;
            a{
                font-size: 12px;
            }
        }   
    }
}

.justStart-sp{
    @include sp{
        justify-content: flex-start;
    }
}
.headerMenuFirst{
    margin-left: 5%;
    @include tab{
        margin-left: 0;   
    }
}
.headerMenuLast{
    margin-right: 5%;
    @include tab{
        margin-right: 0;   
    }
}

.fixBarButton {
    display: block;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #808080;
    transition: 0.3s;
    &:hover{
        background: $mainColorDeep;
        color: #fff;
    }
    @include sp{
        font-size: 12px;   
        width: 85px;
        height: 85px;
    }
}


/****--------- ふわふわ ---------****/
.fixBarButton_anime{
    animation:fwari ease-in-out 2s infinite alternate;
}
@keyframes fwari{
    0%   { transform:translate(0%, 0%); }
    100% { transform:translate(0%, -30px); }
}


.headerAltLogoUnit{
    width: 20%;
    width: 60px;
    margin-right: 3%;
}
.headerAltMenuUnit{
    width: 80%;
}
.headerAltMenuUl{
    li{
        margin-right: 2em;
        position: relative;
        &:before{
            @include hoverBdBottom;
        }
        &:hover{
            &:before{
                background: #000;
            }
        }
    }
}

.headerAlt {
    position: fixed;
    width: 100%;
    z-index: 100;
    background: #fff;
    border-bottom: 1px solid #9f9f9f;
    padding: 7px 0;
    display: none;
}

//ドロップダウンメニュー


.dropmenu{
    *zoom: 1;
    &:before,&:after{
        content: "";
        display: table;        
    }
    &:after{
        clear: both;        
    }
    li{
        ul{
            position: absolute;
            z-index: 9999;
            top: 100%;
            left: 0;
            opacity: 0;
            top: 50%;
            visibility: hidden;
            transition: .5s;
            background: #fff;
            padding: 1.5em 3em 1.5em 1.5em;
            box-shadow: 0px 5px 10px -3px rgba(0, 0, 0, 0.1);            
            font-family: $fontNum;
            font-size: 16px;
            li{
                width: 100%; 
                position: relative;
                white-space: nowrap;
                &:not(:last-child){
                    margin-bottom: 0.5em;                    
                }
                &:before{
                    display: none;
                }
                &:after{
                    content: '';
                    display: block;
                    width: 7px;
                    height: 1px;
                    background: #000;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
                transition: 0.3s;
                &:hover{
                    color: $mainColorDeep;
                    &:after{
                        background: $mainColorDeep;
                    }
                }
                a{
                    margin-left: 15px;
                }
            }
        }
        &:hover{
            ul{
                top: 100%;
                visibility: visible;
                opacity: 1;                
            }
        }
    }
}


/*==================================================
 * フッター
 *================================================*/

footer{
    padding-top: 5%;
    background-color: #333333;
    color: #fff;
    .logo{
        max-width: 100px;
        margin-left: 0;
    }
    @include sp{
        padding-top: 15%;
        .logo{
            max-width: 67px;
        }
    }
}

.footerTextMenuTitle{
    display: block;
    transition: 0.3s;
    &:hover{
        color: $mainColor;
    }
}

.footerTextMenuUl{
    li{
        position: relative;
        &:before{
            content: '';
            display: block;
            width: 7px;
            height: 1px;
            background: #fff;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
        a{
            display: block;
            margin-left: 15px;
            transition: 0.3s;
            &:hover{
                color: $mainColor;
            }
        }
    }
}

.footerTextMenu_button{
    background: #fff;
    color: #000;
    padding: 0.25em 1em;
    transition: 0.3s;
    display: inline-block;
    &.aboutShopButton{
        background: $mainColor;
        border: 1px solid #808080;
        &:hover{
            background: #fff;        
        }
    }
    &:hover{
        background: $mainColor;
        cursor: pointer;
    }
    @include sp{
        padding: 2em 1em;   
        &.aboutShopButton{
            padding: 0.5em 1em;
        }
    }
}

.footerSnsUl{
    display: flex;
    li{
        margin-right: 5%;
    }
}

.footerSnsIco{
    border: 1px solid #fff;
    padding: 5px;
    border-radius: 50%;
    display: block;
    position: relative;
    width: 40px;
    height: 40px;
    i{
        font-size: 20px;
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        border-radius: 50%;
        margin: auto;
        padding: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        display: block;
    }

}

.footerContact{
    border-top: 1px solid #9f9f9f;
    padding: 5% 0;
    @include sp{
        padding: 10% 0;   
    }
}

.footerInfoCard{
    @include sp{
        margin-top: 10%;
        padding-top: 10%;
        padding-bottom: 10%;
        border-top: 1px solid #9f9f9f;
    }
}

.footerContactButton{
    text-align: center;
    padding: 1em 9em;
    display: inline-block;
    border: 1px solid $grayColorLight;
    transition: 0.3s;
    &:hover{
        background: $mainColor;
    }
    @include sp{
        padding: 1em 6em;   
    }
}
.footerCopy{
    padding: 10px 0;
}

.home .footerAboutLink{
    display: none;
}

/*==================================================
 * トップページ
 *================================================*/

$skew: skew(0,45deg);
.stickarrow {
    width: 8px;
    height: 100px;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    transform: $skew;
    position: absolute;
    bottom: 0;
    right: 0;
    animation: sdl 2s cubic-bezier(1, 0, 0, 1) infinite;
}

@keyframes sdl {
  0% {
    transform: $skew scale(1, 0);
    transform-origin: 0 0;
  }
  50% {
    transform: $skew scale(1, 1);
    transform-origin: 0 0;
  }
  50.1% {
    transform: $skew scale(1, 1);
    transform-origin: 0 100%;
  }
  100% {
    transform: $skew scale(1, 0);
    transform-origin: 0 100%;
  }
}
.stickarrow_font{
    transform: rotate(180deg);
    position: absolute;
    bottom: 0;
    left: 0;
}

.stickarrowUnit {
    position: absolute;
    bottom: -5%;
    left: 5%;
    @include sp{
        bottom: -12%;
        left: 10%;        
    }

}

.topFvImg{
    padding-bottom: 45%;
    @include sp{
        padding-bottom: 100%;   
    }
}
.topFvImg{
    margin: auto 3.5%;
}
.slick-dotted.slick-slider {
    margin-bottom: 40px;
}
.slick-dots {
    bottom: -40px;
    li{
        button{
            &:before{
                content: '';
                width: 13px;
                height: 13px;
                background: #333;                
            }
        }
    }
}

.topFv {
    &:before{
        background: $grayColorExLight;
        content: '';
        width: 100%;
        height: 23vw;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    @include sp{
        &:before{
            height: 50px;
        }   
    }
}
.topTopicWrap{
    padding: 4% 7%;
    @include sp{
        padding: 10% 2%;   
    }
}

.flexWrap-sp{
    @include sp{
        flex-wrap: wrap;
    }
}

.topTopicCard_img{
    padding-bottom: 100%;
}

.topTopicCard {
    @include pc{
        &:not(:last-child){
            margin-right: 4%;
        } 
        &.topCate{
            margin:0 2.5%;            
        }
    }
    @include sp{
        &:nth-child(odd){
            padding-right: 2.5%;
        }   
        &:nth-child(even){
            padding-left: 2.5%;
        }
        &:nth-child(n + 3){
            .topArchiveMediaCard &{
                display: none;                
            }
        }
    }
    .journalMainUnit &{
        margin-right: 0;
        padding: 0 1.5%;
        margin-bottom: 4%;
        @include sp{
            padding: 0;
            margin-bottom: 10%;
            &:nth-child(odd){
                padding-right: 3%;                
            }
            &:nth-child(even){
                padding-left: 3%;                
            }
        }
    }
}
.topTopicCard_title{
    transition: 0.3s;
    a:hover &{
        color: $mainColorDeep;
    }
}

.topTopicCard_cate{
    .topArchiveMediaCard &{
        display: none;
    }
}

.topTopicCard_date{
    margin-bottom: 5px;
}

.topResiCard_img{
    padding-bottom: 71%;
    position: relative;
    filter: grayscale(100%);
    transition: 1s;
    a:hover &{
        filter: grayscale(0);
    }
    @include sp{
        filter: grayscale(0);
    }
}
.topResiCard_logo{
    img{
        max-width: 115px;
        @include spTab{
            max-width: 70px;   
        }
    }
}
.topResiCard_bgTriangle{
    &:before{
        width: 0;
        height: 0;
        content: '';
        display: block;
        position: absolute;
        z-index: 1;        
    }
    &.first{
        &:before{        
            border-right: 200px solid transparent;
            border-top: 200px solid #fff;    
            left: 0;
            top: 0;
            @include spTab{
                border-right: 130px solid transparent;
                border-top: 130px solid #fff;                       
            }
        }
    }
    &.last{
        &:before{        
            border-left: 200px solid transparent;
            border-bottom: 200px solid #fff;
            bottom: 0;
            right: 0;   
            @include spTab{
                border-left: 130px solid transparent;
                border-bottom: 130px solid #fff;            
            }
        }
    }
    &:after{
        width: 330px;
        height: 1px;
        content: '';
        display: block;
        transform: rotate(-45deg);
        background: #000;
        position: absolute;
        z-index: 1;
        @include spTab{
            width: 220px;   
        }
    }
    &.first{
        &:after{
            left: -50px;
            top: 84px;
            @include spTab{
                top: 52px;   
                left: -33px;
            }
        }
    }
    &.last{
        &:after{
            right: -50px;
            bottom: 84px;
            @include spTab{
                bottom: 52px;   
                right: -33px;
            }
            
        }
    }

}

.topResiCard_logo {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
}
.topResiCard_info {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
}
.topResiCard{
    margin-bottom: 6%;
    margin-top: 3%;
    &:nth-child(even){
        padding-left: 2.5%;
    }
    &:nth-child(odd){
        padding-right: 2.5%;
    }
    @include sp{
        margin-bottom: 22%;
        margin-top: 15%;
    }
}
.topAboutLink_img{
    padding-bottom: 50%;
    @include sp{
        width: 50%;  
        padding-bottom: 26%;
    }
}
.topAboutLink_text{
    padding: 9% 0;
    background-color: #fff;
    @include sp{
        width: 50%;   
    }
}
.topAboutLinkCard{
    margin: auto 2%;
    @include sp{
        margin: auto;
        margin-bottom: 5%;
    }
}
.topAboutLinkBg{
    position: relative;
    padding-bottom: 5%;
    &:after{
        content: '';
        display: block;
        width: 100%;
        height: 60%;
        background-color: $grayColorExLight;
        position: absolute;
        z-index: -1;
        bottom: 0;
        left: 0;
    }
}
.topAboutLinkInner{
    @include sp{
        display: flex;  
        flex-direction: row-reverse;
    }
}
.topArchiveLessTitle{
    padding-bottom: 5%;
    @include sp{
        padding-bottom: 0;   
    }
}

.topArchiveUnit{
    margin-bottom: 8%;
    position: relative;
    &:after{
        content: '';
        display: block;
        width: 85%;
        height: 75%;
        position: absolute;
        z-index: -1;
        bottom: 0;
        right: -5%;
        background-color: $grayColorExLight;
    }
    margin-bottom: 10%;
    padding-bottom: 2%;
    @include sp{
        &:after{
            width: 110%;
            height: 60%;
            bottom: 0;
            right: -5%;
        }   
    }
}

.topArchiveTitleCard{
    @include sp{
        display: flex;
    }
}
.topArchiveTitle_title{
    @include sp{
        width: 50%;   
    }
}
.topArchiveTitle_button{
    @include sp{
        width: 50%;   
    }
}

.topJournal{
    margin-bottom: 15%;
    @include sp{
        margin-bottom: 30%;   
    }
}

/*==================================================
 * 下層ページ
 *================================================*/

/*共通部分------------------------------------*/
/*パンくず*/


.sectionPankuzu{
    .home &{
        display: none;
    }
}
#pankuzu {
    font-size: 12px;
    color: #727171;
    a{
        color: $mainColorDeep;
    }
    strong{
        font-weight: normal;
    }
    @include sp{
        font-size: 12px;   
    }
}

.sectionPankuzu{
    padding: 10px 0;
    @include sp{
        padding: 6px 0;   
    }
}

/*パンくず終わり*/

.pageHeader_img{
    padding-bottom: 37%;
    .mini &{
        padding-bottom: 24%;
    }
}



/*about------------------------------------*/

.aboutSection{
    &:not(:last-child){
        margin-bottom: 8%;
        padding-bottom: 8%;
        border-bottom: 1px solid #9e9e9e;        
        @include sp{
            margin-bottom: 14%;
            padding-bottom: 14%;   
        }
    }
}
.aboutSubMenuUnit{
    position: absolute;
    right: 0;
    top: -0.5em;
    @include sp{
        position: static;
        margin-bottom: 8%;
    }
}

.aboutSubMenu_ul{
    li{
        margin-right: 1em;
        &:not(:last-child){
            padding-right: 1em;
            position: relative;
            &:after{
                content: '';
                display: block;
                border-right: 1px solid #000;
                transform: skewX(150deg) translateY(-50%);
                width: 1px;
                height: 2em;
                position: absolute;
                right: 1em;
                top: 50%;
            }            
        }
    }
    @include sp{
        overflow-x: scroll;
    }
}

.aboutSubMenu_text{
    display: block;
    position: relative;
    text-align: center;
    white-space: nowrap;
    &:before{
        @include hoverBdBottom;
    }
    .active &{
        &:before{
            background: #000;        
        }
    }
}

.slick-prev,.slick-next{
    width: 19px;
    height: 50px;    
    &:before{
        position: relative;
        color: #fff;
        font-family: "Font Awesome 5 pro";
        font-weight: 300;
        content: "\f104";
        font-size: 50px;
    }
}

.slick-prev {
    left: 10px;
    z-index: 100;
}

.slick-next{
    right: 10px;
    z-index: 100;
    &:before{
        content: "\f105";        
    }
}
.slick-counter {
    text-align: right;
    letter-spacing: 0.01em;
}
.aboutShopSlide{
    li{
        margin-bottom: 7px;        
    }
}
.aboutSection_text{
    margin-left: 10%;
    @include sp{
        margin-left: 0;   
    }
    @include tab{
        margin-left: 5%;   
    }
}

/*single------------------------------------*/


.singleEntry_titleInfo{
    margin-bottom: 5%;
    position: relative;
    @include sp{
        margin-bottom: 12%;   
    }
}
.singleEntry_entry{
    max-width: 760px;
    margin-bottom: 5%;
    @include sp{
        margin-bottom: 12%;   
    }
}
.singleEntry_imgWrap {
    max-width: 760px;
    margin: 0 auto;
    margin-bottom: 5%;
    img{
        width: 100%;
    }
    @include sp{
        margin-bottom: 12%;   
    }
}
.singleHeaderLess {
    padding-top: 85px;
    margin-bottom: 7%;
    @include sp{
        padding-top: 30px;   
        margin-bottom: 12%;
    }
}
.singleTitleSub{
    position: absolute;
    right: 0;
    bottom: 1em;
}


.singleEntryOtherSection{
    border-top: 1px solid #9e9e9e;
    border-bottom: 1px solid #9e9e9e;
    padding: 5% 0;
    margin-bottom: 2%;
    @include sp{
        padding: 12% 0;
        margin-bottom: 1%;
    }
}
/*residentsシングル------------------------------------*/

.singleResiEntry_num{
    &:after{
        content: '';
        display: block;
        width: 0.8em;
        height: 4px;
        background: #000;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0.1em;
    }
}

.singleResiEntry_logoWrap {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    @include sp{
        max-width: 160px;   
    }
}
.singleResiEntry_Cat{
    font-size: 220px;
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0.2;
    z-index: -1;
    letter-spacing: 0;
    line-height: 0.7;
    @include tab{
        font-size: 160px;   
    }
    @include sp{
        font-size: 100px;   
    }
}

.singleResiEntry_time {
    margin-top: 2em;
}

/*residents一覧------------------------------------*/
.archiveResiTitle{
    &:after{
        content: '';
        display: block;
        width: 2.5em;
        height: 3px;
        background: #000;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0.4em;
        margin-bottom: 0.7em;
    }
}